@use "../commons/colors" as colors;
@use "../commons/fonts" as fonts;

footer {
    display: flex;
    align-items: center;
    justify-content: center;
    @include fonts.lightFontWeight_300;
    margin: 80px 20px 40px;
    gap: 15px;
    flex-wrap: wrap;

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }

    * {
        color: #9F9F9F;
        user-select: none;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;

        .externalIcon {
            transition: 0.3s;
            width: 15px;
            margin-left: 8px;
            filter: invert(78%) sepia(0%) saturate(973%) hue-rotate(144deg) brightness(83%) contrast(86%);
        }

        &:hover {
            color: white;
            cursor: pointer;

            .externalIcon {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(299deg) brightness(107%) contrast(107%);
            }
        }
    }

    .scrollTop a {
        text-decoration: underline;
    }
}

@media (max-width: 900px){
    footer {
        .scrollTop {
            width: 100%;
            margin-top: 30px;

            .separator {
                display: none;
            }
        }
    }
}

@media (max-width: 600px){
    footer {
        .copyright {
            width: 100%;

            .separator {
                display: none;
            }
        }
    }
}