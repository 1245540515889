@use "../commons/colors" as colors;
@use "../commons/fonts" as fonts;

.about {
    font-size: 18px;
    margin-top: 100px;

    .presentation-wrapper {
        position: relative;
        width: 100dvw !important;
        max-width: 100dvw !important;
        display: flex;
        justify-content: center;
        padding-top: 35px;
        padding-bottom: 35px;
        background: colors.$card-background;
        border-top: colors.$card-border 1px solid;
        border-bottom: colors.$card-border 1px solid;

        .presentation {
            display: flex;
            flex-direction: column;
            gap: 20px;
            position: relative;

            .tag {
                font-size: 16px;
                color: #989898;
                user-select: none;
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 20px;
                border-left: 1px #989898 solid;
                padding-left: 30px;
                margin-left: 30px;

                p {
                    span {
                        color: colors.$accent-2;
                    }
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .about {
        .presentation-wrapper {
            .presentation {
                .tag {
                    display: none;
                }

                .text {
                    margin: 0;
                    padding: 0;
                    border: none;
                }
            }
        }
    }
}