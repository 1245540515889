@mixin lightFontWeight_300 {
    font-weight: 300;
}

@mixin regularFontWeight_400 {
    font-weight: 400;
}

@mixin mediumFontWeight_500 {
    font-weight: 500;
}

@mixin semiBoldFontWeight_600 {
    font-weight: 600;
}