// Main colors
$paragraph: #F0F1F9;
$sub-paragraph: #bebebe;

$accent-1: #9A5BFF;
$accent-2: #01BE94;

$error: #d03c3c;

// Page
$page-background: #0F0F0F;

// Cards
$card-background: #0c0c0c;
$card-border: #1e1e1e;
$card-label-text: #cecece;
$card-sub-label-text: #9F9F9F;

// Buttons
$button-standby-text: #cecece;
$button-standby-border: #323232;

$button-hover-text: #fff;
$button-hover-border: #757575;

$button-active-text: #fff;
$button-active-border: #fff;

// Footer
$footer-text: #9F9F9F;
$footer-hover: #fff;