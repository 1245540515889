@use "../commons/colors" as colors;

.pre {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background: colors.$page-background;
    z-index: 1000;
}

.fade-out {
     opacity: 0;
     transition: opacity 0.3s ease-out;
}