@use "../commons/colors" as colors;
@use "../commons/fonts" as fonts;

.skills {
    .section-content {
        .rows {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .row {
                display: flex;
                gap: 30px;

                .skillbox {
                    width: 100%;
                    padding: 30px;
                    background: colors.$card-background;
                    border: 1px colors.$card-border solid;
                    border-radius: 20px;

                    .title {
                        display: flex;
                        justify-content: space-between;
                        gap: 20px;
                        margin-bottom: 20px;

                        h4 {
                            font-size: 20px;
                            color: colors.$accent-2;
                        }

                        img {
                            height: 18px;
                            opacity: 50%;
                        }
                    }

                    .body {
                        span {
                            user-select: none;
                            cursor: pointer;

                            &:hover {
                                color: colors.$paragraph;
                            }
                        }
                    }

                }
            }
        }
    }
}

@media (max-width: 850px) {
    .skills .section-content .rows {

        .row {
            flex-direction: column;

            .skillbox {
                width: unset !important;
            }
        }
    }
}