@use "../commons/fonts" as fonts;
@use "../commons/colors" as colors;

.pre .error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-right: 30px;
    margin-left: 30px;

    * {
        text-align: center;
        color: colors.$sub-paragraph;
    }

    h1 {
        font-size: 25px;
        color: colors.$error;
    }

    p {
        font-size: 18px;
    }

    a {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: white;
        }
    }
}