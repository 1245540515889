@use 'commons/colors' as colors;
@use 'commons/fonts' as fonts;
@use 'commons/breakpoints' as breakpoints;

* {
    margin: 0;
    padding: 0;
    color: colors.$paragraph;
    font-family: 'Roboto Mono', monospace;
    scroll-margin: 100px;
}

a {
    text-decoration: none;
}

body {
    background: colors.$page-background;
    overflow-x: hidden;

    &.loading {
        pointer-events: none;
        overflow: hidden;
    }
}

.homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .page-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .section {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 100px;

            .section-title, .section-details {
                margin-left: 30px;
                margin-right: 30px;
            }

            .section-title {
                color: colors.$accent-1;
                font-size: 40px;
                margin-top: 40px;
                margin-bottom: 40px;

                display: flex;
                gap: 20px;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .section-details {
                text-align: center;
                color: colors.$sub-paragraph;
                margin-bottom: 70px;
            }

            .section-content {
                width: 90vw;
                max-width: 1100px;
            }
        }
    }
}

.comment {
    display: flex;
    gap: 10px;
    overflow: hidden;

    p {
        color: #989898;
    }
}

@media (max-width: 500px) {
    .homepage .page-body .section {
        .section-title {
            font-size: 27px;
        }
    }
}