.pre .loader {
    @keyframes pulse {
        from {
            opacity: 1;
            transform: scale(1);
        }
        to {
            opacity: .25;
            transform: scale(.75);
        }
    }

    .pulse-container {
        width: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pulse-bubble {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #ffffff;
        }

        .pulse-bubble-1 {
            animation: pulse .5s ease 0s infinite alternate;
        }
        .pulse-bubble-2 {
            animation: pulse .5s ease .2s infinite alternate;
        }
        .pulse-bubble-3 {
            animation: pulse .5s ease .4s infinite alternate;
        }
    }
}