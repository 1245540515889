@use "../commons/colors" as colors;
@use "../commons/fonts" as fonts;

.header {
    height: 100dvh;
    max-height: 1500px;
    min-height: 800px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    .header-content {
        display: flex;
        gap: 100px;
        align-items: center;

        .text {
            display: flex;
            flex-direction: column;
            gap: 40px;
            height: fit-content;
            margin-right: 50px;

            .title {
                h1 {
                    @include fonts.semiBoldFontWeight_600;
                    font-size: 50px;

                    span {
                        color: colors.$accent-1;
                    }
                }
            }

            .comment {
                max-width: 600px;
            }

            .buttons {
                display: flex;
                gap: 40px;
                margin-top: 40px;

                .button {
                    padding: 15px 20px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    border-radius: 10px;

                    .icon {
                        height: 100%;
                        transition: 0.3s;
                        width: 0;
                        overflow: hidden;

                        p {
                            margin-left: 10px;
                        }
                    }

                    &.icon-visible .icon {
                        width: 20px;
                    }

                    p {
                        @include fonts.semiBoldFontWeight_600;
                    }

                    &.green {
                        border: 2px solid colors.$accent-2;

                        p {
                            color: colors.$accent-2;
                        }
                    }

                    &.violet {
                        border: 2px solid colors.$accent-1;

                        p {
                            color: colors.$accent-1;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .header {
        min-height: 700px !important;

        .avatar {
            display: none !important;
        }

        .header-content {
            .text {
                margin: 0;
            }
        }
    }
}

@media (max-width: 800px) {
    .header {
        min-height: 600px !important;

        .header-content {
            .text {
                margin-left: 30px;
                margin-right: 30px;

                .title {

                    h1 {
                        font-size: 6.8vw;
                    }
                }

                .buttons {
                    display: none;
                }
            }
        }
    }
}
