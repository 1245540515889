@use "../commons/colors" as colors;
@use "../commons/fonts" as fonts;

.menu {
    position: absolute;
    width: 100%;
    top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;

    .wrapper {
        width: 100%;
        max-width: 1500px;
        margin: 10px 40px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .menu-box {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            h2 {
                @include fonts.semiBoldFontWeight_600;
                font-size: 20px;
                cursor: pointer;

                span {
                    color: colors.$accent-2;
                }
            }

            .button {
                padding: 10px 15px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background: colors.$paragraph;
                height: 20px;
                gap: 15px;

                p {
                    color: black;
                    @include fonts.semiBoldFontWeight_600;
                    font-size: 15px;
                }

                img {
                    height: 18px;
                }
            }
        }

        .burger-button {
            display: none;
        }
    }
}

.navigation {
    gap: 30px !important;

    p {
        position: relative;
        @include fonts.semiBoldFontWeight_600;
        padding-bottom: 3px;
        cursor: pointer;

        &:after {
            content: '';
            width: 0%;
            height: 2px;
            background: colors.$accent-1;
            position: absolute;
            bottom: -5px;
            left: 0;
            transition: width .3s;
        }

        &:hover, &.active {
            &:after {
                width: 100%;
            }
        }
    }
}

.contact {
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin-right: 30px;

    .contact-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: opacity 0.3s;
        position: relative;

        img {
            max-width: 25px;
            max-height: 25px;
        }

        &:after {
            content: '';
            width: 0;
            height: 2px;
            background: colors.$accent-1;
            position: absolute;
            bottom: -5px;
            left: 0;
            transition: width .3s;
        }

        &:hover {
            opacity: 100%;

            &:after {
                width: 100%;
            }
        }
    }
}

.menu-scroll {
    background: #141414;
    position: fixed;
    top: -80px;
    height: 75px;
    transition: top .3s;

    &.show {
        display: flex;
        top: -1px;
        -webkit-box-shadow: 0px -5px 25px 2px #000000;
        box-shadow: 0px -5px 25px 2px #000000;
    }
}

.menu-mobile {
    display: none;
}


@media (max-width: 800px) {
    .menu {
        .wrapper {
            .menu-box {
                .contact, .button {
                    display: none;
                }

                &.navigation {
                    display: none;
                }
            }

            .burger-button {
                display: flex;
                cursor: pointer;
                position: relative;

                img {
                    height: 20px;
                }

                &:hover {
                    &:after {
                        position: absolute;
                        content: '';
                        width: 50px;
                        height: 50px;
                        border: 1px solid colors.$card-border;
                        z-index: -10;
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    .menu-mobile-wrapper {
        .menu-mobile {
            background: #141414;
            display: flex;
            flex-direction: column;
            position: fixed;
            height: 100dvh;
            width: 250px;
            top: 0;
            right: -310px;
            align-items: start;
            z-index: 200;
            padding-left: 30px;
            padding-right: 30px;
            justify-content: space-between;
            transition: 0.3s;

            .top {
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 10px;

                p {
                    font-size: 20px;
                    color: colors.$accent-1;
                    font-weight: 600;
                }

                img {
                    height: 20px;
                    cursor: pointer;
                }
            }

            .navigation {
                display: flex;
                flex-direction: column;
                width: 100%;

                gap: 50px !important;

                p {
                    font-size: 20px;
                    width: fit-content;
                }
            }

            .bottom {
                margin-bottom: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                .contact {
                    margin-right: 0 !important;
                }
            }
        }

        &.shown {
            .menu-mobile {
                -webkit-box-shadow: 0px -5px 25px 2px #000000;
                box-shadow: 0px -5px 25px 2px #000000;
                right: 0px;
            }

            .overlay {
                background: rgba(0, 0, 0, 0.5);
                position: fixed;
                height: 100dvh;
                width: 100dvw;
                left: 0;
                top: 0;
                z-index: 100;
            }
        }
    }

}

@media (max-width: 500px) {
    .menu {
        .wrapper {
            margin: 10px 20px 10px !important;
        }
    }
}