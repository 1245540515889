.header .scrollDown {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;

    img {
        padding-bottom: 50px;
        animation: upAndDown 2.5s infinite;
        animation-timing-function: ease;
        cursor: pointer;
        width: 22px;
    }

    @keyframes upAndDown {
        0% {
            padding-bottom: 50px;
        }

        50% {
            padding-bottom: 25px;
        }

        100% {
            padding-bottom: 50px;
        }
    }
}