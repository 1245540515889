@use "../commons/colors" as colors;
@use "../commons/fonts" as fonts;

.header .avatar {
    position: relative;
    height: 550px;
    display: flex;
    align-items: end;

    img {
        height: 450px;
        padding-left: 30px;
        padding-right: 30px;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: colors.$card-background;
        border: 1px colors.$card-border solid;
        border-radius: 5%;
    }

    .light {
        position: absolute;
        z-index: -10;
        border-radius: 100px;
        height: 150px;
        width: 50px;

        &.light-1 {
            left: 70px;
            top: 70px;
            box-shadow: 0 0 500px 10px rgb(0, 255, 184);
        }

        &.light-2 {
            right: 70px;
            bottom: 70px;
            box-shadow: 0 0 500px 30px rgb(154, 91, 255);
        }
    }
}