@use "../commons/colors" as colors;
@use "../commons/fonts" as fonts;

.projects .section-content {
    .selector {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        margin-bottom: 80px;

        .selector-wrapper {
            display: flex;
            justify-content: center;
            gap: 10px;
            padding: 10px;
            border-radius: 10px;
            border: colors.$button-standby-border solid 1px;
            background: colors.$page-background;
            position: relative;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                border-radius: 10px;
                position: absolute;
                box-shadow: 0 0 50px 1px rgba(255, 255, 255, 0.1);
                z-index: -10;
            }

            p {
                padding: 20px;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 5px;
                color: colors.$button-standby-text;
                border: 1px solid colors.$page-background;
                cursor: pointer;
                transition: 0.3s;

                &.active {
                    background: #2a2a2a;
                    color: colors.$paragraph;
                    border: 1px solid #444444;
                }

                &:hover {
                    &:not(.active) {
                        background: colors.$card-border;
                        border: 1px solid #313131;
                    }
                }
            }
        }
    }

    .cards {
        .no-results {
            display: flex;
            align-items: center;
            justify-content: center;
            @include fonts.mediumFontWeight_500;

            width: 100%;
            flex-direction: column;
            gap: 30px;
            text-align: center;

            p {
                color: #bbbbbb;
            }
        }

        .columns {
            display: flex;
            gap: 30px;
            min-height: 500px;

            .col {
                display: flex;
                flex-direction: column;
                gap: 30px;
                flex: 1;
                overflow: hidden;

                .card {
                    display: flex;
                    flex-direction: column;

                    .thumb {
                        width: 100%;
                        aspect-ratio: 16/9;
                        object-fit: cover;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    }

                    .info {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        background: colors.$card-background;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        border: 1px #1e1e1e solid;
                        border-top: none;
                        padding: 20px;

                        h3 {
                            @include fonts.mediumFontWeight_500;
                            font-size: 23px;
                        }

                        .description {
                            @include fonts.regularFontWeight_400;
                            font-size: 15px;
                            color: #e5e5e5;
                            text-align: justify;
                        }

                        .tags {
                            display: flex;
                            gap: 10px;
                            margin-top: 10px;

                            .icon {
                                padding-top: 3px;
                                img {
                                    width: 15px;
                                }
                            }

                            p {
                                color: #9F9F9F;
                                @include fonts.semiBoldFontWeight_600;
                            }
                        }

                        .links {
                            display: flex;
                            justify-content: center;
                            gap: 30px;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .buttonlink {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                @include fonts.mediumFontWeight_500;

                                background: colors.$card-background;
                                border-radius: 5px;
                                cursor: pointer;
                                transition: 0.3s;

                                user-select: none;

                                padding: 8px 15px;

                                .externalIcon {
                                    width: 15px;
                                    height: 15px;
                                    margin-left: 10px;
                                }
                            }

                            .buttonlink-green {
                                color: colors.$accent-2;
                                border: colors.$accent-2 1px solid;

                                .externalIcon {
                                    filter: invert(50%) sepia(19%) saturate(5046%) hue-rotate(134deg) brightness(100%) contrast(99%);
                                }
                            }

                            .buttonlink-violet {
                                color: colors.$accent-1;
                                border: colors.$accent-1 1px solid;

                                .externalIcon {
                                    filter: invert(42%) sepia(37%) saturate(4819%) hue-rotate(239deg) brightness(100%) contrast(102%);
                                }
                            }

                            .buttonlink-violet:hover {
                                box-shadow: 0 0 20px 1px rgba(154, 91, 255, 0.2);
                            }

                            .buttonlink-green:hover {
                                box-shadow: 0 0 20px 1px rgba(1, 190, 148, 0.2);
                            }
                        }
                    }
                }
            }
        }

        .more {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 60px;

            .show-more {
                text-align: center;
                min-width: max-content;
                cursor: pointer;
                height: 55px;

                p {
                    @include fonts.mediumFontWeight_500;
                    color: #9F9F9F;
                    font-size: 16px;
                    transition: 0.3s;
                }

                img {
                    margin-top: 3px;
                    opacity: 50%;
                    width: 25px;
                    height: 25px;
                    transition: 0.3s;
                }

                &:hover {
                    p {
                        color: white;
                    }

                    img {
                        opacity: 100%;
                        margin-top: 6px;
                    }
                }
            }
        }
    }
}

@mixin tablet {
    .projects .section-content {
        .cards {
            min-height: 300px;
        }
    }
}

@mixin mobile {
    .projects .section-content {
        .settings {

            .title {
                display: none;
            }

            .filter {
                height: 26px;

                img {
                    margin: 0;
                }
            }
        }

        .filter-wrapper {
            img {
                right: 10px;
            }
        }

        .cards {
            .col {
                gap: 20px;
            }
        }
    }
}